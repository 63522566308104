import { explorerPlugin } from "@graphiql/plugin-explorer"
import GraphiQL from "graphiql"
import { getIntrospectionQuery } from "graphql"
import React, { FormEvent, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { CopyPlaygroundToolbarButton } from "./CopyPlaygroundToolbarButton"
import * as Environment from "./Environment"
import * as Schema from "./Schema"
import { Button, Select } from "./Ui"
import { usePersistedState } from "./usePersistedState"

import "@graphiql/plugin-explorer/dist/style.css"

type Schema = Schema.Schema
type Environment = Environment.Environment

export type State = { type: "LOADING" } | { type: "SIGNED_OUT" } | { type: "SIGNED_IN" }

function copyToClipboard(text: string) {
  const textarea = document.createElement("textarea")
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand("copy")
  document.body.removeChild(textarea)
}

export function createGraphQLFetcher(token: string, environment: Environment, schema: Schema) {
  return function GraphQLFetcher(graphQLParams: any) {
    return fetch("https://" + schema.url + environment.url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization-Firebase": token,
        "x-client-version": "Web GraphQL Explorer",
      },
      body: JSON.stringify(graphQLParams),
    } as any).then((response) => response.json())
  }
}

function App() {
  const env = window.location.hostname === "graphql-explorer.vcm.sh" ? "production" : "staging"
  const environment = Environment[env]
  const initialRefreshToken = localStorage.getItem("refreshToken")

  const [fetcher, setFetcher] = useState<any>(null)
  const [token, setToken] = useState<string | null>(null)
  const [schema, setSchema] = usePersistedState(Schema.app, "schema")
  const [introspectionResult, setIntrospectionResult] = useState("")
  const [state, setState] = useState<State>({ type: initialRefreshToken ? "LOADING" : "SIGNED_OUT" })

  if (initialRefreshToken) {
    getAndSetFbaToken(initialRefreshToken)
  }

  const explorer = explorerPlugin({ showAttribution: true })

  useEffect(() => {
    if (token) {
      const fetcher = createGraphQLFetcher(token, environment, schema)
      setFetcher(() => fetcher)

      fetcher({ query: getIntrospectionQuery() }).then((json: any) => {
        setIntrospectionResult(JSON.stringify(json.data, null, 2))
      })

      setState({ type: "SIGNED_IN" })
    } else {
      setFetcher(null)
    }
  }, [token, schema])

  async function getAndSetFbaToken(providedRefreshToken: string) {
    const response = await fetch(
      environment.name === "staging"
        ? "https://securetoken.googleapis.com/v1/token?key=AIzaSyB0J2SrypqFE4qfdEGAR054X9qrBPxYpxE"
        : "https://securetoken.googleapis.com/v1/token?key=AIzaSyAf7prkqnH9XlJZM04uaPsn1AwnY9ODpUw",
      {
        method: "POST",
        headers: {},
        body: JSON.stringify({
          grant_type: "refresh_token",
          refresh_token: providedRefreshToken,
        }),
      },
    )
    const { id_token }: { id_token: string } = await response.json()
    setToken(id_token)
  }

  function saveRefreshToken(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const input = form.elements.namedItem("refreshToken") as HTMLInputElement

    localStorage.setItem("refreshToken", input.value)

    const refreshToken = localStorage.getItem("refreshToken")
    if (refreshToken) {
      getAndSetFbaToken(refreshToken)
    } else {
      throw new Error(JSON.stringify({ msg: "No refreshToken", value: refreshToken }))
    }
  }

  function viewLoading() {
    return (
      <section>
        <h2>Loading ...</h2>
      </section>
    )
  }

  function viewLoggedOut() {
    return (
      <section className="signedOut">
        <div>
          <h2>Enter refresh token to login</h2>
          <form onSubmit={saveRefreshToken}>
            <label htmlFor="refreshToken">Refresh Token</label>
            <br />
            <input id="refreshToken" name="refreshToken" type="text" placeholder="Your refresh token" size={50} />
            <br />
            <br />
            <input type="submit" value="Login" />
          </form>
          <p>You can get a refresh token in three different ways:</p>
          <ul>
            <li>
              For your <strong>Google account</strong>, run this in a terminal <i>(click-to-copy)</i>:
              <br />
              <pre
                className="copyable"
                onClick={() => {
                  copyToClipboard(
                    `curl -H "Authorization: Bearer $(gcloud auth print-identity-token)" ${environment.studioUrl} | pbcopy`,
                  )
                  window.alert("CURL script copied into clipboard")
                }}
              >
                <code>
                  curl -H "Authorization: Bearer $(gcloud auth print-identity-token)" {environment.studioUrl}\ | pbcopy
                </code>
              </pre>
            </li>
            <li>
              For a test user with another m.co address (like `your.name+whatever@m.co`): On iOS, log in as the user in
              the app, give the phone a shake and use <i>Get refreshToken for current user</i> in Developer Settings to
              copy the token
            </li>
            <li>
              For a test user with non-m.co email/password: <b>TBD</b>
            </li>
            <li>
              For an anonymous user: <b>TBD</b>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  function viewSignedIn() {
    return (
      <div className="graphiql-container">
        <GraphiQL
          fetcher={fetcher}
          plugins={[explorer]}
          toolbar={{
            additionalComponent: () => <CopyPlaygroundToolbarButton />,
          }}
        />
      </div>
    )
  }

  function viewSwitch(state: State) {
    switch (state.type) {
      case "LOADING":
        return viewLoading()
      case "SIGNED_OUT":
        return viewLoggedOut()
      case "SIGNED_IN":
        return viewSignedIn()
    }
  }

  return (
    <main className="app">
      <section className="controls">
        {token ? (
          <Select
            options={Schema.all.map((e) => e.name)}
            value={schema.name}
            onChange={(name) => setSchema(Schema.fromName(name))}
          />
        ) : (
          <span />
        )}
        {introspectionResult ? (
          <Button
            text="Copy introspection"
            onClick={() => {
              copyToClipboard(introspectionResult)
            }}
          />
        ) : (
          <span />
        )}
        {token && (
          <Button
            text="Sign out"
            onClick={() => {
              setToken(null)
              localStorage.removeItem("refreshToken")
              setState({ type: "SIGNED_OUT" })
            }}
          />
        )}
      </section>
      {viewSwitch(state)}
    </main>
  )
}

const container = document.getElementById("app")
const root = createRoot(container!)
root.render(<App />)
